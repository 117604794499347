import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types'
import he from 'he'
import { handleAnchorTags } from '../utils'

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: {
          fields: { file, title },
        },
      },
    }) => {
      return <img alt={title['nl']} src={file['nl'].url} />

    },
  },
}

const RichText = ({
  json,
  isEmbeded,
  isHeader,
  hasAnchor,
}) => {
  if(isEmbeded || isHeader || hasAnchor) {
    let html = documentToHtmlString(json)

    if(hasAnchor) {
      html = handleAnchorTags(html)

    } else if(isEmbeded) {  
      html = html.replace('<p>', '').replace('</p>', '')
  
    } else if(isHeader) {
      html = html.replace('<p>', '<h1>').replace('</p>', '</h1>')
    }

    html = he.decode(html);
    
    return <div dangerouslySetInnerHTML={{__html: html}} /> 

  } else {
    return documentToReactComponents(json, options)
  }
  
}

export default RichText
