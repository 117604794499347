import React, { useContext } from 'react'
import Helmet from 'react-helmet'
import { DictionaryContext } from '../contexts'
import { getPathname } from '../utils'
import { LayoutContext } from '../contexts'

const SEO = ({
  title,
  description,
  image,
  slug,
  isBlog,
  isBlogPost,
  isProduct,
  isPage,
}) => {
  const dictionary = useContext(DictionaryContext)
  const { node_locale } = useContext(LayoutContext)
  const ogImage = image || '/favicon.png'
  const seoDescription = description || dictionary.seoFallbackDescription

  return (
    <Helmet>
      {/* General tags */}
      <html lang={node_locale} />
      <title>{title}</title>
      <meta name='description' content={seoDescription} />
      {/* <meta name='image' content={`https:${ogImage}`} /> */}
      <link
        rel='canonical'
        href={`https://isla-beauty.com${getPathname(node_locale, slug)}`}
      />
      {/* OpenGraph tags */}
      <meta
        property='og:url'
        content={`https://isla-beauty.com${getPathname(node_locale, slug)}`}
      />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={seoDescription} />
      <meta name="google-site-verification" content="Os7laFKVqYGW3pqFCA7CwbnyNBVFcGE7w5LJ0HPzIN8" />
      <meta name="facebook-domain-verification" content="yqq81biom4i6g23vo6ecgozcbnbxae" />
      {ogImage && <meta property='og:image' content={ogImage} />}
      {isPage && <meta property='og:type' content='page' />}
      {isBlog && <meta property='og:type' content='blog' />}
      {isBlogPost && <meta property='og:type' content='article' />}
      {isProduct && <meta property='og:type' content='product' />}
      <script defer src="https://static.myshlf.us/Affiliates/sms_aff_clicktrack-isla.js"></script>
      <script>{`
        const urlParams = window.location.search.slice(1).split('&').reduce((res, val) => ({ ...res, [val.split('=')[0]]: val.split('=')[1] }), {});
        document.querySelectorAll('a[href*="isla-us.myshopify.com"]').forEach(a => {
        a.href=\`\${a.href}\${urlParams.smsclickid ? \`&smsclickid=\${urlParams.smsclickid}\` : ''}\${urlParams.smscode ? \`&smscode=\${urlParams.smscode}\` : ''}\`
        })
      `}
    </script>
    </Helmet>
  )
}

export default SEO
